const routes = {
  path: "/comercial",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
			props: true,
      component: () => import("./views/Index.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' },
      children: [
        {
          path: "/",
          name: "leads",
          props: true,
          component: () => import("./components/Leads.vue"),
          meta: { requiresAuth: true, acceso: 'EVENTOS' }
        },
        {
          path: "/evolutivo",
          name: "evolutivo",
          props: true,
          component: () => import("./components/Evolutivo.vue"),
          meta: { requiresAuth: true, acceso: 'EVENTOS' }
        },
        {
          path: "lead/:idLead?",
          name: "datosLead",
          props: true,
          component: () => import("./views/NuevoLead.vue"),
          meta: { requiresAuth: true, acceso: 'EVENTOS' }
        },
      ]
      },
  ],
};

export default routes;
