const routes = {
  path: "/precio-medio",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "precioMedio",
			props: true,
      component: () => import("./views/AvgBodas.vue"),
      meta: { requiresAuth: true, acceso: 'RENTABILIDAD' }
    },
    // {
    //   path: "presupuesto/:idPresupuesto?",
    //   name: "datosPresupuesto",
		// 	props: true,
    //   component: () => import("./views/NuevoPresupuesto.vue"),
    //   meta: { requiresAuth: true },
    // },
  ],
};

export default routes;
