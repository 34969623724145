
const routes =
  {
    path: "/escandallo",
    component: () => import("./Module.vue"),
    children: [
      {
        path: "",
        name: "Escandallo",
        component: () => import("./views/Escandallo.vue"),
        meta: { requiresAuth: true, acceso: 'ESCANDALLO' }
      },
    ]
    
  }


export default routes;
