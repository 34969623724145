const routes = {
  path: "/necesidades",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "stock",
      name: "Stock",
			props: true,
      component: () => import("./views/Stock.vue"),
      meta: { requiresAuth: true, acceso: 'NECESIDADES' }
    },
    {
      path: ":date",
      name: "Necesidades",
			props: true,
      component: () => import("./views/Necesidades.vue"),
      meta: { requiresAuth: true, acceso: 'NECESIDADES' }
    }
  ],
};

export default routes;
