const routes = {
  path: "/perfil_equipo",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "/",
      name: "PerfilEquipo",
			props: true,
      component: () => import("./views/PerfilEquipo.vue"),
      meta: { requiresAuthExtra: true }
    },
  ],
};

export default routes;
