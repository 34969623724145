const routes = {
  path: "/horas",
  component: () => import("./Module.vue"),
  children: [
    {
      path: ":date",
      name: "Horas",
			props: true,
      component: () => import("./views/Horas.vue"),
      meta: { requiresAuth: true, acceso: 'CONTROL_HORAS' }
    },
  ],
};

export default routes;
