import Vue from "vue";
import App from "./App.vue";
import router from "./router.js";
import store from "./store.js";
import vuetify from "./plugins/vuetify";

import VueMeta from "vue-meta";
Vue.use(VueMeta);

Vue.use(router);
Vue.use(store);

Vue.config.errorHandler = err => {
    alert("Error en consola: " + err);
	console.error(err)
};

Vue.config.productionTip = false;

import axios from "axios";

if (store.getters.getTokenExtra){
	window.axios = axios.create({
		headers: { Authorization: `Bearer ${store.getters.getTokenExtra}` },
		baseURL: process.env.VUE_APP_API_URL,
	});
}
else{
	window.axios = axios.create({
		headers: { Authorization: `Bearer ${store.getters.getToken}` },
		baseURL: process.env.VUE_APP_API_URL,
	});
}

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
	methods: {
		acceso: (value) => {
			return store.getters.getTokenPayload?.permisos ? ( store.getters.getPermisos && !!(store.getters.getPermisos[value] & Number(store.getters.getTokenPayload.permisos))) : false;
		},
	},
}).$mount("#app");
