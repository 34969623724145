const routes = {
  path: "/kpi",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
			props: true,
      component: () => import("./views/Index.vue"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "/",
          name: "kpi",
          props: true,
          component: () => import("./components/Ocupacion.vue"),
          meta: { requiresAuth: true, acceso: 'DIARIO' }
        },
        {
          path: "/ventas",
          name: "ventas",
          props: true,
          component: () => import("./components/Ventas.vue"),
          meta: { requiresAuth: true, acceso: 'DIARIO' }
        },
        {
          path: "/placas",
          name: "placas",
          props: true,
          component: () => import("./components/Placas.vue"),
          meta: { requiresAuth: true, acceso: 'DIARIO' }
        },
      ]
      },
  ],
};

export default routes;