const routes = {
  path: "/citas",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "citas",
			props: true,
      component: () => import("./views/Citas.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
    {
      path: "cita/:idCita?",
      name: "datosCita",
			props: true,
      component: () => import("./views/NuevaCita.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
  ],
};

export default routes;
