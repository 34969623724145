const routes = {
  path: "/jornadas",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "NuevaJornada",
			props: true,
      component: () => import("./views/Jornadas.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
    // {
    //   path: ":idJornada",
    //   name: "EditarJornada",
		// 	 props: true,
    //   component: () => import("./views/Editar.vue"),
    //   meta: { requiresAuth: true }
    // },
    
  ],
};

export default routes;
