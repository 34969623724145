const routes = {
  path: "/fichaje_equipo",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "/",
      name: "FichajeEquipo",
      props: true,
      component: () => import("./views/FichajeEquipo.vue"),
      meta: { requiresAuthExtra: true }
    }
  ],
};

export default routes;
