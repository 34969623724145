const routes = {
  path: "/nominas",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Nominas",
			props: true,
      component: () => import("./views/Nominas.vue"),
      meta: { requiresAuth: true, acceso: 'PERSONAS' }
    },
    {
      path: "nueva",
      name: "NuevaNomina",
      component: () => import("./views/Nueva.vue"),
      meta: { requiresAuth: true, acceso: 'PERSONAS' }
    },
  ],
};

export default routes;
