const routes = {
  path: "/horas_mensuales",
  component: () => import("./Module.vue"),
  children: [
    {
      path: ":idEmpleado",
      name: "horasMesIndividual",
      props: true,
      component: () => import("./views/HorasMensualesIndividual.vue"),
      meta: { requiresAuth: true, acceso: 'CONTROL_HORAS' }
    },
    {
      path: "",
      name: "horasMes",
			props: true,
      component: () => import("./views/HorasMensuales.vue"),
      meta: { requiresAuth: true, acceso: 'CONTROL_HORAS' }
    },
  ],
};

export default routes;
