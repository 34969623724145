import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import es from "vuetify/es5/locale/es";

const mq = window.matchMedia("(prefers-color-scheme: dark)");
const storedTheme = localStorage.getItem("theme");
const dark = storedTheme === null ? mq.matches : JSON.parse(storedTheme);

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: dark,
    options: {
			customProperties: true,
		},
    themes: {
      light: {
        primary: '#d39652',
        neutral: '#000'
      },
      dark: {
        primary: '#d39652',
        neutral: '#fff'
      }
    }
  },
  lang: {
		locales: { es },
		current: "es",
	},
});
