const routes = {
  path: "/contacto_equipo",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "/",
      name: "ContactoEquipo",
      props: true,
      component: () => import("./views/ContactoEquipo.vue"),
      meta: { requiresAuthExtra: true }
    }
  ],
};

export default routes;
