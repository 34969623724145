const routes = {
  path: "/listado",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "listado",
			props: true,
      component: () => import("./views/Listado.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "boda/:idAdHoc?",
      name: "datosBoda",
			props: true,
      component: () => import("./views/AhBoda.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "comunion/:idAdHoc?",
      name: "datosComunion",
			props: true,
      component: () => import("./views/AhComunion.vue"),
      meta: { requiresAuth: true },
    },
  ],
};

export default routes;
