import axios from "axios";

const store = {
  state: {
    token: localStorage.getItem("token") || null,
    tokenExtra: localStorage.getItem("tokenExtra") || null,
    pfp: localStorage.getItem("pfp") || null,
    pwdChanged: JSON.parse(localStorage.getItem("pwdChanged")) || null,
		permisos: JSON.parse(localStorage.getItem("permisos")) || null,
    // firebaseToken: localStorage.getItem("firebaseToken") || null,
  },
  getters: {
    getToken: (state) => state.token,
    getTokenExtra: (state) => state.tokenExtra,
    getPfp: (state) => state.pfp,
		getTokenPayload: (state) => state.token != null ? JSON.parse(atob(state.token.split(".")[1])) : null,
		getTokenExtraPayload: (state) => state.tokenExtra != null ? JSON.parse(atob(state.tokenExtra.split(".")[1])) : null,
		getPermisos: (state) => state.permisos,
		getPwdChanged: (state) => state.pwdChanged,
    // getFirebaseToken: (state) => state.firebaseToken,
  },
  actions: {
    login({ commit }, { user, pass }) {
      return new Promise(async (resolve, reject) => {
        try {
					const formData = new FormData()
					formData.append("username", user)
					formData.append("password", pass)
          const { data } = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_URL}/login`,
            data: formData,
          });

					const { access_token : token } = data;

          const { data: permisos } = await axios({ method: "GET", url: `${process.env.VUE_APP_API_URL}/permisos`, headers: { Authorization: `Bearer ${token}` } });

					let per = {};
          permisos.forEach(
            ({ permiso, valor }) => (per[permiso] = Number(valor))
          );

          window.axios = axios.create({
            headers: { Authorization: `Bearer ${token}` },
  					baseURL: process.env.VUE_APP_API_URL
          });

          localStorage.setItem('permisos', JSON.stringify(per))
          await commit("setPermisos", per);

          localStorage.setItem('token', token)
          await commit("login", token);
          resolve()
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });
    },
    changePwd({ commit }, { user, pass }) {
      return new Promise(async (resolve, reject) => {
        try {
					const formData = new FormData()
					formData.append("username", user)
					formData.append("password", pass)

          const { data } = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_URL}/disponibilidad/changePwd`,
            data: formData,
            headers: { Authorization: `Bearer ${localStorage.getItem("tokenExtra")}` }
          });

          const pwdChanged = data

          localStorage.setItem('pwdChanged', pwdChanged)
          await commit("changePwd", pwdChanged);
          resolve()
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });
    },
    loginExtra({ commit }, { user, pass }) {
      return new Promise(async (resolve, reject) => {
        try {
					const formData = new FormData()
					formData.append("username", user)
					formData.append("password", pass)
          const { data } = await axios({
            method: "POST",
            url: `${process.env.VUE_APP_API_URL}/loginExtra`,
            data: formData,
          });

					const tokenExtra = data.token;
					const pfp = data.pfp;
					const pwdChanged = data.pwdChanged;

          const { data: permisos } = await axios({ method: "GET", url: `${process.env.VUE_APP_API_URL}/permisos`, headers: { Authorization: `Bearer ${tokenExtra}` } });

					let per = {};
          permisos.forEach(
            ({ permiso, valor }) => (per[permiso] = Number(valor))
          );

          window.axios = axios.create({
            headers: { Authorization: `Bearer ${tokenExtra}` },
  					baseURL: process.env.VUE_APP_API_URL
          });

          localStorage.setItem('permisos', JSON.stringify(per))
          await commit("setPermisos", per);
          localStorage.setItem('tokenExtra', tokenExtra)
          localStorage.setItem('pfp', pfp)
          localStorage.setItem('pwdChanged', pwdChanged)
          
          // // Send Firebase token to server if available
          // const firebaseToken = state.firebaseToken;
          // if (firebaseToken) {
          //   try {
          //     await axios.post(
          //       `${process.env.VUE_APP_API_URL}/registerFirebaseToken`,
          //       { token: firebaseToken },
          //       { headers: { Authorization: `Bearer ${tokenExtra}` } }
          //     );
          //   } catch (error) {
          //     console.error("Failed to register Firebase token:", error);
          //   }
          // }
          
          await commit("loginExtra", tokenExtra, pfp);
          resolve()
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });
    },
    logout({ commit }) {
      return new Promise(async (resolve, reject) => {
        localStorage.removeItem('token')
				localStorage.removeItem("permisos");
        await commit("logout");
        resolve();
      });
    },
    logoutExtra({ commit }) {
      return new Promise(async (resolve, reject) => {
        localStorage.removeItem('tokenExtra')
        localStorage.removeItem('pfp')
        localStorage.removeItem('pwdChanged')
        await commit("logoutExtra");
        resolve();
      });
    },
    // updateFirebaseToken({ commit }, firebaseToken) {
    //   // Update Firebase token in store and localStorage
    //   localStorage.setItem("firebaseToken", firebaseToken);
    //   commit("updateFirebaseToken", firebaseToken);

    //   // Send Firebase token to server if user is logged in
    //   const tokenExtra = state.tokenExtra;
    //   if (tokenExtra) {
    //     try {
    //       axios.post(
    //         `${process.env.VUE_APP_API_URL}/registerFirebaseToken`,
    //         { token: firebaseToken },
    //         { headers: { Authorization: `Bearer ${tokenExtra}` } }
    //       );
    //     } catch (error) {
    //       console.error("Failed to register Firebase token:", error);
    //     }
    //   }
    // },
  },
  mutations: {
    // updateFirebaseToken(state, firebaseToken) {
    //   state.firebaseToken = firebaseToken;
    // },
    login(state, token) {
      return new Promise(async (resolve) => {
        state.token = token;
        resolve();
      });
    },
    changePwd(state, changePwd) {
      return new Promise(async (resolve) => {
        state.changePwd = changePwd;
        resolve();
      });
    },
    loginExtra(state, tokenExtra, pfp, pwdChanged) {
      return new Promise(async (resolve) => {
        state.tokenExtra = tokenExtra;
        state.pfp = pfp;
        state.pwdChanged = pwdChanged;
        resolve();
      });
    },
    logout(state) {
      return new Promise(async (resolve) => {
        state.token = null;
        resolve();
      });
    },
    logoutExtra(state) {
      return new Promise(async (resolve) => {
        state.tokenExtra = null;
        state.pfp = null;
        state.pwdChanged = null;
        resolve();
      });
    },
		setPermisos(state, per) {
      return new Promise(async (resolve) => {
        localStorage.setItem("permisos", JSON.stringify(per));
        state.permisos = per;
        resolve();
      });
    },
  },
};

export default store;