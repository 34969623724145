const routes = {
  path: "/tapas",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Tapas",
			props: true,
      component: () => import("./views/Tapas.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
  ],
};

export default routes;
