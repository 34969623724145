<template>
  <v-app :style="`background: ${$vuetify.theme.dark ? '' : '#e4e4e4'}`">
    <v-navigation-drawer
      app
      v-if="$store.getters.getToken"
      :mini-variant="menuShow"
      :color="$vuetify.theme.dark ? '#1E1E1E' : 'primary'"
      :disable-route-watcher="true"
      inset
      permanent
      >
      <div class="d-flex">
        <v-img
          class="flex-grow-1 shrink"
          @click.stop="$router.push({ name: 'Index' }).catch(()=>{})"
          src="/img/grupomas.png"
        ></v-img>
      </div>

      <v-tabs
        :background-color="$vuetify.theme.dark ? '' : 'primary'"
        vertical
        slider-size="5"
        class="mt-5"
      >
        <template v-for="el in menu">
          <h3 class="ms-3" :style="'color:' + ($vuetify.theme.dark ? 'var(--v-primary-base)' : 'black')">{{ menuShow ? el.title.split(" ").splice(-1)[0].slice(0,3) : el.title }}</h3>
          <v-tab
            v-for="link in el.links.filter(
              (link) => !link.acceso || $root.acceso(link.acceso)
            )"
            exact-active-class="active-tab"
            style="height: 40px"
            class="rounded-r-pill mr-1"
            :to="link.url"
            :key="link.url"
          >
          
          <v-list-item-icon class="my-auto">
              <v-icon v-if="$vuetify.theme.dark" :style="'color:' + link.color" v-text="link.icon"></v-icon>
              <v-icon v-else :style="(link.noShadow ? '' : 'text-shadow: 1px 1px black;') + ' color:' + link.colorLight" v-text="link.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content
              class="text-start white--text"
              style="font-size: 0.7rem"
            >
              <div class="d-flex" :style="'color: ' + ($vuetify.theme.dark ? 'white' : 'black')" >{{ link.texto }}</div>
            </v-list-item-content>
          </v-tab>
        </template>
      </v-tabs>

      <template v-slot:append>
					<v-divider></v-divider>

				<v-list dense>

					<v-list-item @click.stop="menuShow = !menuShow">
						<v-list-item-icon>
            	<v-icon :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'" :style="menuShow ? 'transform: rotate( 180deg );' : ''" >mdi-menu-open</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="text-button" style="font-size: 12px !important;" :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'">Contraer menú</v-list-item-title>
					</v-list-item>

          <v-list-item @click.stop="changeTheme">
						<v-list-item-icon>
            	<v-icon :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'" v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
              <v-icon :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'" v-else>mdi-weather-night</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="text-button" style="font-size: 12px !important;" :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'">
              {{ $vuetify.theme.dark ? "Tema oscuro" : "Tema claro"}}
						</v-list-item-title>
					</v-list-item>

          <v-list-item @click.stop="$router.push({ path : '/usuarios' })" v-if="$root.acceso('USUARIOS')">
						<v-list-item-icon>
            	<v-icon :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'">mdi-account</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="text-button" style="font-size: 12px !important;" :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'">
              USUARIOS
						</v-list-item-title>
					</v-list-item>

					<v-list-item>
						<v-list-item-avatar>
            	<v-avatar size="30" :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'">
                <span :class="'font-weight-bold ' + ($vuetify.theme.dark ? 'secondary' : 'primary') + '--text'">{{ $store.getters.getTokenPayload.usuario.slice(0, 2).toUpperCase() }}</span>
              </v-avatar>
						</v-list-item-avatar>
						<v-list-item-title class="text-button" style="font-size: 12px !important;" :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'">{{ $store.getters.getTokenPayload.usuario }}</v-list-item-title>
					</v-list-item>

					<v-list-item @click.stop="logout">
						<v-list-item-icon>
            	<v-icon :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'">mdi-logout-variant</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="text-button" style="font-size: 12px !important;" :color="$vuetify.theme.dark ? 'var(--v-primary-base)' : 'black'">
							Cerrar sesión
						</v-list-item-title>
					</v-list-item>

				</v-list>
      </template>
    </v-navigation-drawer>

    <v-app-bar
      color="primary"
      app
        v-if="$store.getters.getTokenExtra"
      >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <!-- <NotificacionEquipo /> -->
      <v-btn icon @click.stop="changeTheme">
        <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
        <v-icon v-else>mdi-weather-night</v-icon>
      </v-btn>
      <v-menu
        offset-x
        rounded
        >
        <v-list dense>
          <h3 class="text-center pb-2">
            {{ $store.getters.getTokenExtraPayload.nombre_completo }}
          </h3>
          <v-divider></v-divider>
          <v-list-item @click="$router.push({ name: 'PerfilEquipo' }).catch(()=>{})">
            <v-list-item-icon class="justify-center">
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="font-size: large;">Perfil</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="logoutExtra">
            <v-list-item-icon class="justify-center">
              <v-icon color="error">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title style="color: var(--v-error-base);font-size: large;">Cerrar sesión</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
      </v-menu>
    </v-app-bar>

    <!-- app -->
    <v-navigation-drawer
      v-model="drawer"
      v-if="$store.getters.getTokenExtra"
      :color="$vuetify.theme.dark ? '#1E1E1E' : 'primary'"
      absolute
      temporary
      >
      <div class="d-flex">
        <v-img
          class="flex-grow-1 shrink"
          @click.stop="$router.push({ name: 'IndexExtra' }).catch(()=>{})"
          src="/img/grupomas.png"
        ></v-img>
      </div>

      <v-tabs
        :background-color="$vuetify.theme.dark ? '' : 'primary'"
        vertical
        slider-size="5"
        class="mt-5"
      >
        <v-tab
          v-for="link in menuExtra"
          exact-active-class="active-tab"
          style="height: 40px"
          class="rounded-r-pill mr-1"
          :to="link.url"
          :key="link.url"
        >
        
        <v-list-item-icon class="my-auto">
            <v-icon v-if="$vuetify.theme.dark" :style="'color:' + link.color" v-text="link.icon"></v-icon>
            <v-icon v-else :style="'text-shadow: 1px 1px black; color:' + link.colorLight" v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content
            class="text-start white--text"
            style="font-size: 0.7rem"
          >
            <div class="d-flex" :style="'color: ' + ($vuetify.theme.dark ? 'white' : 'black')" >{{ link.texto }}</div>
          </v-list-item-content>
        </v-tab>
      </v-tabs>

    </v-navigation-drawer>

    <v-main>
      <v-container fluid class="pa-0">
        <router-view :key="$route.fullPath" />
      </v-container>
      <v-snackbar v-model="snack" timeout="4000" color="primary" type="error">
        {{ msg }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  components: {
		// NotificacionEquipo: () => import('./components/NotificacionEquipo.vue')
  },
  computed:{
    // menuFiltered(){
    //   console.log(this.$root.acceso('PRICING'));
    //   console.log(this.$root.acceso('ESCANDALLO'));
    //   var m = this.menu
    //   m.forEach((men,i) => {
    //     var hasAccess = false
    //     men.links.forEach(link => {
    //       if (!link.acceso && this.$root.acceso(link.acceso)) hasAccess = true
    //     });
    //     if (!hasAccess) {
    //       m.splice(i,1)
    //       i--
    //     }
    //   });
    //   m.forEach((men,i) => {
    //     var hasAccess = false
    //     men.links.forEach(link => {
    //       if (!link.acceso && this.$root.acceso(link.acceso)) hasAccess = true
    //     });
    //     if (!hasAccess) {
    //       m.splice(i,1)
    //       i--
    //     }
    //   });
    //   m.forEach((men,i) => {
    //     var hasAccess = false
    //     men.links.forEach(link => {
    //       if (!link.acceso && this.$root.acceso(link.acceso)) hasAccess = true
    //     });
    //     if (!hasAccess) {
    //       m.splice(i,1)
    //       i--
    //     }
    //   });
    //   m.forEach((men,i) => {
    //     var hasAccess = false
    //     men.links.forEach(link => {
    //       if (!link.acceso && this.$root.acceso(link.acceso)) hasAccess = true
    //     });
    //     if (!hasAccess) {
    //       m.splice(i,1)
    //       i--
    //     }
    //   });
    //   return m
    // }
  },
  metaInfo() {
    return {
      title: `Gestión eventos | Grupo Mas Farre`,
      link: [
        {
          rel: "icon",
          href: `./img/grupomas.png`,
        },
      ],
    };
  },
  data() {
    return {
      msg: "",
      drawer: null,
      drawer2: null,
      menuShow: true, 
      snack: false,
      development: process.env.NODE_ENV == "development",
      menu: [
        {
          title: "GESTIÓN COMERCIAL",
          links:[
            { acceso:"EVENTOS", noShadow: true, url: "/", texto: "Reservas", icon: "mdi-home", color: "white", colorLight: "black" },
            { acceso:"EVENTOS", noShadow: true, url: "/pendientes", texto: "Pendientes", icon: "mdi-timer-sand", color: "white", colorLight: "black" },
            { acceso:"EVENTOS", noShadow: true, url: "/presupuestos", texto: "Operativa", icon: "mdi-file-sign", color: "white", colorLight: "black" },
            { acceso:"EVENTOS", noShadow: true, url: "/citas", texto: "Citas", icon: "mdi-handshake-outline", color: "white", colorLight: "black" },
            { acceso:"EVENTOS", noShadow: true, url: "/comercial", texto: "Dpto Comercial", icon: "mdi-invoice-list", color: "white", colorLight: "black" },
            { acceso:"EVENTOS", noShadow: true, url: "/listado", texto: "Eventos", icon: "mdi-file-document-plus-outline", color: "white", colorLight: "black" },
            { acceso:"EVENTOS", noShadow: true, url: "/informes", texto: "Informes", icon: "mdi-finance", color: "white", colorLight: "black" },
            // { url: "/eventos/festivos", texto: "Festivos", icon: "mdi-party-popper", color: "white", colorLight: "black" },
            // { url: "/jornadas", texto: "Jornadas", icon: "mdi-timetable", color: "white", colorLight: "black" },
            // { url: "/eventos", texto: "Eventos", icon: "mdi-calendar", color: "white", colorLight: "black" },
            // { url: "/eventos/vault", texto: "Realizados", icon: "mdi-archive-lock-outline", color: "white", colorLight: "black" }
          ]
        },
        {
          title: "GESTIÓN TARIFAS",
          links:[
            { url: "/escandallo", texto: "Escandallos", icon: "mdi-food-turkey", acceso: 'ESCANDALLO', color: "#4caf50", colorLight: "#4caf50" },
            { url: "/pricing", texto: "Pricing", icon: "mdi-hand-coin", acceso: 'PRICING', color: "#4caf50", colorLight: "#4caf50" },
          ]
        },
        {
          title: "GESTIÓN DEL EVENTO",
          links:[
            { url: "/equipo/editar", texto: "Equipo", icon: "mdi-account-group", acceso: 'PERSONAS', color: "#EB44B6", colorLight: "#E252F2" },
            { url: "/necesidades/stock", texto: "Inventario", icon: "mdi-glass-wine", acceso: 'NECESIDADES', color: "#EB44B6", colorLight: "#E252F2" },
            { url: "/aproximaciones", texto: "Aproximaciones", icon: "mdi-calendar-question", acceso: 'NECESIDADES', color: "#EB44B6", colorLight: "#E252F2" },
            { url: "/check_catering/"+(new Date((Date.now() - ((new Date()).getTimezoneOffset() * 60000)) - (24*60*60*1000))).toISOString().substr(0, 10), texto: "Check Catering", icon: "mdi-truck", acceso: 'NECESIDADES', color: "#EB44B6", colorLight: "#E252F2" },
            { acceso:"EVENTOS", url: "/tapas", texto: "Tapas y Platos", icon: "mdi-food-variant", color: "#EB44B6", colorLight: "#E252F2" },
            { acceso:"EVENTOS", url: "/tareas", texto: "Planificador", icon: "mdi-calendar-sync", color: "#EB44B6", colorLight: "#E252F2" },
          ]
        },
        {
          title: "CONTROL ECONÓMICO",
          links:[
            { url: "/costes", texto: "Rentabilidad", icon: "mdi-alarm-panel", acceso: 'RESUMEN_RENTABILIDAD', color: "#53ADD4", colorLight: "#2491BF" },
            { url: "/precio-medio", texto: "Avg Bodas", icon: "mdi-approximately-equal", acceso: 'RESUMEN_RENTABILIDAD', color: "#53ADD4", colorLight: "#2491BF" },
            { url: "/horas_mensuales/", texto: "Horas Mensuales", icon: "mdi-human-male", acceso: 'CONTROL_HORAS', color: "#53ADD4", colorLight: "#2491BF" },
            { url: "/kpi/", texto: "KPI", icon: "mdi-chart-pie", color: "#53ADD4", acceso: 'DIARIO', colorLight: "#2491BF" }
          ]
        }
        // { url: "/usuarios", texto: "Usuarios", icon: "mdi-account", acceso: "CONTROL_USUARIOS", color: "white", colorLight: "white" },
      ],
      menuExtra: [
        { url: "/portal_equipo/", texto: "Disponibilidad", icon: "mdi-calendar-edit-outline", color: "white", colorLight: "black" },
        { url: "/documentos_equipo/", texto: "Documentos Obligatorios", icon: "mdi-file-document", color: "white", colorLight: "black" },
        { url: "/eventos_equipo/", texto: "Asistencia Eventos", icon: "mdi-party-popper", color: "white", colorLight: "black" },
        { url: "/fichaje_equipo/", texto: "Fichajes", icon: "mdi-account-clock", color: "white", colorLight: "black" },
        { url: "/formacion_equipo/", texto: "Formación", icon: "mdi-school", color: "white", colorLight: "black" },
        { url: "/contacto_equipo/", texto: "Contacto", icon: "mdi-face-agent", color: "white", colorLight: "black" },
        { url: "/tareas/", texto: "Planificador", icon: "mdi-calendar-sync", color: "white", colorLight: "black" },
        // { url: "/perfil_equipo", texto: "Perfil", icon: "mdi-account", color: "white", colorLight: "black" },
      // { url: "/disponibilidad_equipo", texto: "Disponibilidad", icon: "mdi-calendar-edit-outline", color: "white", colorLight: "black" },
      ],
    };
  },
  methods: {
    logout() {
      confirm("¿Estás seguro de que quieres cerrar sesión?")
        ? this.$store
            .dispatch("logout")
            .then(() => this.$router.push({ name: "Login" }))
        : null;
    },
    logoutExtra() {
      this.drawer2 = false
      confirm("¿Cerrar sesión?")
        ? this.$store
            .dispatch("logoutExtra")
            .then(() => this.$router.push({ name: "LoginExtra" }))
        : null;
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("theme", this.$vuetify.theme.dark);
    },
  },
  mounted() {
    this.$root.$on("snack", (msg) => {
      this.snack = false;
      this.snack = true;
      this.msg = msg;
    });
    window.onerror = function(message, source, lineno, colno, error) {
    alert('Mensaje: ' + message + '\nOrigen: ' + source + '\nLínea: ' + lineno + '\nCol: ' + colno + '\nError: ' + error);
    return false;
    }
  },
};
</script>

<style lang="scss">
.border-l {
  border-left: 5px solid transparent;
}
.border-l-white {
  border-left: 5px;
}
.border-l {
  border-left: 5px solid transparent;
}
.border-l-white {
  border-left: 5px solid white;
}
.active-tab {
  background-color: #00000036;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: var(--v-primary-base);
}
*.theme--dark ::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #121212;
}
*::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #bebebe;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: #f1f1f1 !important;
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: #272727 !important;
}

.v-data-table.fixed-checkbox
  table
  > tbody
  > tr
  > td:nth-child(1):not(.remove-filter),
.v-data-table.fixed-checkbox
  table
  > thead
  > tr
  > th:nth-child(1):not(.remove-filter) {
  // position: sticky !important;
  // position: -webkit-sticky !important;
  // left: 0;
  position: sticky;
  left: 0;
  z-index: 4;
  background: inherit;
}

.v-data-table.theme--light
  table
  > tbody
  > tr:not(.v-data-table__selected):not(:hover)
  > td:nth-child(1):not(.remove-filter),
.v-data-table.theme--light
  table
  > thead
  > tr:not(.v-data-table__selected):not(:hover)
  > th:nth-child(1):not(.remove-filter) {
  background: #ffffff;
}

.v-data-table.theme--dark
  table
  > tbody
  > tr:not(.v-data-table__selected):not(:hover)
  > td:nth-child(1):not(.remove-filter),
.v-data-table.theme--dark
  table
  > thead
  > tr:not(.v-data-table__selected):not(:hover)
  > th:nth-child(1):not(.remove-filter) {
  background: #1e1e1e;
}
</style>