const routes = {
  path: "/eventos_equipo",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "/",
      name: "EventosEquipo",
      props: true,
      component: () => import("./views/EventosEquipo.vue"),
      meta: { requiresAuthExtra: true }
    }
  ],
};

export default routes;
