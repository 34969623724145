const routes = {
  path: "/costes",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "ResumenEscandallo",
			props: true,
      component: () => import("./views/Resumen.vue"),
      meta: { requiresAuth: true, acceso: 'RESUMEN_RENTABILIDAD' }
    },
    {
      path: ":date",
      name: "ControlCostes",
			props: true,
      component: () => import("./views/ControlCostes.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
  ],
};

export default routes;
