import store from '@/store'

const routes = {
  path: "/auth",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "login",
      name: "Login",
      component: () => import("./views/Login.vue"),
      beforeEnter: (to, from, next) => {
        if (store.getters.getToken) next({ name: "Index" });
        else next();
      },
    },
    {
      path: "login_equipo",
      name: "LoginExtra",
      component: () => import("./views/LoginExtra.vue"),
      beforeEnter: (to, from, next) => {
        if (store.getters.getTokenExtra) next({ name: "IndexExtra" });
        else next();
      },
    },
  ],
};

export default routes;