const routes = {
  path: "/eventos",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Eventos",
      component: () => import("./views/Eventos.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
    {
      path: "vault",
      name: "Vault",
      component: () => import("./views/Eventos.vue"),
			props: {
				all: true
			},
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
    {
      path: "nuevo",
      name: "Nuevo",
      component: () => import("./views/Nuevo.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
		{
			path: "festivos",
			name: "Festivos",
			component: () => import("./views/Festivos.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
		},
    {
			path: ":id",
      name: "Evento",
      component: () => import("./views/Nuevo.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
    {
			path: ":id/informe",
      name: "Informe",
      component: () => import("./views/GenerarInforme.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
  ],
};

export default routes;
