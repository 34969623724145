const routes = {
  path: "/usuarios",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "Usuarios",
      component: () => import("./views/Usuarios.vue"),
      meta: { requiresAuth: true, acceso: 'PERSONAS' }
    },
    {
      path: "nuevo",
      name: "NuevoUsuario",
      component: () => import("./views/Nuevo.vue"),
      meta: { requiresAuth: true }
    },
    {
      path: ":id",
      name: "Usuario",
      component: () => import("./views/Nuevo.vue"),
      meta: { requiresAuth: true }
    },
  ],
};

export default routes;
