const routes = {
  path: "/planificacion",
  component: () => import("./Module.vue"),
  children: [
    {
      path: ":date",
      name: "Planificacion",
			props: true,
      component: () => import("./views/Planificacion.vue"),
      meta: { requiresAuth: true, acceso: 'EVENTOS' }
    },
    {
      path: "/equipo/editar",
      name: "EditarEquipo",
			props: true,
      component: () => import("./views/Equipo.vue"),
      meta: { requiresAuth: true, acceso: 'PERSONAS' }
    },
  ],
};

export default routes;
