const routes = {
  path: "/tareas",
  component: () => import("./Module.vue"),
  children: [
    {
      path: "",
      name: "tareas",
			props: true,
      component: () => import("./views/Tareas.vue"),
      meta: { requiresAuthBoth: true, acceso: 'EVENTOS' }
    },
    {
      path: "tarea/:idTarea?",
      name: "datosTarea",
			props: true,
      component: () => import("./views/NuevaTarea.vue"),
      meta: { requiresAuthBoth: true, acceso: 'EVENTOS' }
    },
  ],
};

export default routes;
