
const routes =
  {
    path: "/pricing",
    component: () => import("./Module.vue"),
    children: [
      {
        path: "",
        name: "Pricing",
        component: () => import("./views/Pricing.vue"),
        meta: { requiresAuth: true, acceso: 'PRICING' }
      },
    ]
    
  }


export default routes;
