import Vuex from 'vuex'
import Vue from 'vue';

Vue.use(Vuex)

import auth from '@/modules/auth/index.js';
import events from '@/modules/events/index.js';

export default new Vuex.Store({
	modules: {
		auth : auth.store,
		events : events.store
	}
})